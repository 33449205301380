
import { arrayOf, bool, shape } from 'vue-types';
import { customLinkArray } from '~/types/customLink';
import { isNullableString } from '~/types/isNullable';
import { visualList } from '~/types/visual';
import { layoutPropType } from '~/components/grids/CardGrid.vue';
import { images } from '~/types/image';
export default {
  inheritAttrs: false,
  props: {
    heading: isNullableString(),
    products: arrayOf(
      shape({
        visual: visualList,
        label: isNullableString(),
      }).loose,
    ),
    link: customLinkArray,
    layout: layoutPropType,
    overview: bool().def(false),
    illustration: images,
  },
};
