
import { arrayOf, bool, object, oneOf, string } from 'vue-types';
import customLink from '~/mixins/customLink';
import { customLinkArray } from '~/types/customLink';
import { images } from '~/types/image';
import { isNullableString } from '~/types/isNullable';

export const LAYOUT_HIGHLIGHT = 'highlight';
export const LAYOUT_EVEN = 'even';
export const LAYOUT_AUTO = 'auto';
export const LAYOUT_OVERVIEW = 'overview';

export const layoutPropType = oneOf([LAYOUT_OVERVIEW, LAYOUT_HIGHLIGHT, LAYOUT_EVEN, LAYOUT_AUTO]).def(LAYOUT_AUTO);

export default {
  mixins: [customLink],
  props: {
    heading: isNullableString(),
    link: customLinkArray,
    full: bool().def(false),
    illustration: images,
    layout: layoutPropType,
    cards: arrayOf(object()),
    cardComponent: string(),
    extra: object().def({}),
  },
  computed: {
    cols() {
      if (this.layout === LAYOUT_HIGHLIGHT || this.layout === LAYOUT_EVEN) {
        return 'l:grid-cols-4';
      }

      if ([1, 2].includes(this.cards?.length)) {
        return 'l:grid-cols-2';
      }

      if ([3, 6, 9].includes(this.cards?.length)) {
        return 'l:grid-cols-3';
      }

      return 'l:grid-cols-4';
    },
    highlight() {
      return this.layout === LAYOUT_HIGHLIGHT || (this.layout === LAYOUT_AUTO && this.cards?.length === 5);
    },
    landscape() {
      return this.layout === LAYOUT_AUTO && [1, 2].includes(this.cards?.length);
    },
  },
};
